import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import api from "./hubbapi";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    last_fetch: null,
    last_update: null,
    config: {},
    app_blocks: [],
    cms_pages: [],
    events: [],
    organisations: [],
    categories: [],
    links: [],
    opportunities: [],
    surveys: [],
    survey_questions: [],
    documents: [],
    connected: navigator.onLine,
    current_lang: "",
    last_remote_update: undefined,
    openDyslexic: false,
    offers: [],
    news: [],
    languages: [
      { name: "English", code: "en" },
      { name: "Kurdish | Kurdî", code: "ku" },
      { name: "Kurdish (Sorani) | کوردی سۆرانی", code: "ckb" },
      { name: "Pashto | پښتو", code: "ps" },
      { name: "Sundanese | basa Sunda", code: "su" },
      { name: "Arabic | عربي", code: "ar" },
      { name: "Turkish | Türkçe", code: "tr" },
      { name: "Albanian | shqip", code: "sq" },
      { name: "Tigrinya | ትግሪኛ", code: "ti" },
      { name: "Amharic | አማርኛ", code: "am" },
    ],
    networkAlert: false,
    locations: [
      {
        text: "All Locations",
        value: "All Locations",
      },
      "Bath and North East Somerset",
      "Bournemouth, Christchurch and Poole",
      "Bristol",
      "Cornwall",
      "Devon",
      "Dorset",
      "Gloucestershire",
      "Isles of Scilly",
      "North Somerset",
      "Plymouth",
      "Somerset",
      "South Gloucestershire",
      "Swindon",
      "Torbay",
      "Wiltshire",
    ],
    userLocation: localStorage.getItem("user_location"),
    userType: localStorage.getItem("user_type"),
  },
  getters: {
    // currentConversation: state => state.current_conversation,
    translations: (state) => {
      return state.config.translations;
    },
    contact: (state) => {
      return state.config.contact;
    },
    rootCmsPages: (state) => {
      return state.cms_pages.filter((cms_page) => cms_page.parent_id == "cms_page_");
    },
    getCmsPageById: (state) => (cms_page_id) => {
      var page = state.cms_pages.find((cms_page) => cms_page.id === cms_page_id);
      return page;
    },
    getCmsPageByPath: (state) => (path) => {
      var page = state.cms_pages.find((cms_page) => cms_page.path === path);
      return page;
    },
    getCmsPageByCategory: (state) => (category_id) => {
      return state.cms_pages.find((cms_page) => cms_page.category_ids.indexOf(category_id) > -1);
    },
    getOrganisationsByCategory: (state) => (category_id) => {
      return state.organisations.filter((organisations) => organisations.category_ids.indexOf(category_id) > -1);
    },
    getDocumentsByCategory: (state) => (category_id) => {
      return state.documents.filter((documents) => documents.category_ids.indexOf(category_id) > -1);
    },
    getLinksByCategory: (state) => (category_id) => {
      return state.links.filter((links) => links.category_ids.indexOf(category_id) > -1);
    },
    getOrganisationsById: (state) => (organisations_id) => {
      return state.organisations.find((organisations) => organisations.id === organisations_id);
    },
    getOpportunityById: (state) => (opportunity_id) => {
      return state.opportunities.find((opportunity) => opportunity.id === opportunity_id);
    },
    getEventById: (state) => (events_id) => {
      return state.events.find((events) => events.id === events_id);
    },
    getCategoryPageById: (state) => (category_id) => {
      return state.categories.find((categories) => categories.id === category_id);
    },
    getCategoriesPageByCategory: (state) => (category_id) => {
      let id = category_id.replace(/^\D+/g, "");
      return state.categories.filter((categories) => categories.ancestry === id);
    },
    getCmsPagesByParentId: (state) => (parent_id) => {
      return state.cms_pages.filter((cms_page) => cms_page.parent_id == parent_id);
    },
    getRootCategories: (state) => {
      return state.categories.filter((categories) => categories.ancestry == null);
    },
    getOfferById: (state) => (offerId) => {
      return state.offers.find((offer) => offer.id === offerId);
    },
    getNewsArticles(state) {
      return state.news;
    },
    getLanguages(state) {
      return state.languages;
    },
    getNetworkAlert(state) {
      return state.networkAlert;
    },
    getPages: (state) => {
      return state.cms_pages;
    },
    getUserLocation: (state) => {
      return state.userLocation;
    },
    getUserType: (state) => {
      return state.userType;
    },
    getLocations: (state) => {
      let locations = state.locations;

      locations[0].text = state.config.translations.welcome.all_locations;

      return locations;
    },
  },
  mutations: {
    setLastUpdate(state, last_update) {
      state.last_update = last_update;
    },
    setLastFetch(state, last_fetch) {
      state.last_fetch = last_fetch;
    },
    setConfig(state, config) {
      state.config = config;
    },
    setAppBlocks(state, app_blocks) {
      state.app_blocks = app_blocks;
    },
    setCmsPages(state, cms_pages) {
      state.cms_pages = cms_pages;
    },
    setOrganisations(state, organisations) {
      state.organisations = organisations;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    setLinks(state, links) {
      state.links = links;
    },
    setDocuments(state, documents) {
      state.documents = documents;
    },
    setSurveys(state, surveys) {
      state.surveys = surveys;
    },
    setSurveyQuestions(state, survey_questions) {
      state.survey_questions = survey_questions;
    },
    setEvents(state, events) {
      state.events = events;
    },
    setOpportunities(state, opportunities) {
      state.opportunities = opportunities;
    },
    setLang(state, selectedLang) {
      localStorage.setItem("current_lang", selectedLang);
      this.state.current_lang = selectedLang;
      //to set html language
      const html = document.documentElement;
      html.setAttribute("lang", this.state.current_lang);
    },
    setFont(state, font) {
      this.state.openDyslexic = font;
    },
    addAnswer(state, answer) {
      state.answers.push({
        answer: answer.answer,
        questions_id: answer,
      });
    },
    setOffers(state, offers) {
      state.offers = offers;
    },
    setNews(state, news) {
      state.news = news;
    },
    setNetworkAlert(state, networkStatusBool) {
      console.log("setNetworkAlert", networkStatusBool);
      state.networkAlert = networkStatusBool;
    },
    setUserLocation(state, userLocation) {
      state.userLocation = userLocation;
    },
    setUserType(state, userType) {
      state.userType = userType;
    },
  },
  mounted() {
    if (localStorage.getItem("current_lang") !== null) {
      this.state.current_lang = localStorage.getItem("current_lang");
    } else {
      this.state.current_lang = "en";
    }
  },
  actions: {
    updateLang({ commit }, selectedLang) {
      commit("setLastFetch", null);
      commit("setLang", selectedLang);
    },

    updateFont({ commit }, font) {
      commit("setFont", font);
    },

    addAnswer({ commit }, answer) {
      commit("addAnswer", answer);
    },

    submitAnswers(thing, data) {
      api.saveSurveyAnswers(data);
    },
    setFont() {
      if (localStorage.getItem("enable_font_change") == "true") {
        this.state.openDyslexic = true;
      } else {
        this.state.openDyslexic = false;
      }
    },
    initDB({ dispatch, state }) {
      // if it has been longer than 5 mins since last getting the database
      if (state.last_fetch !== null && Date.now() - state.last_fetch > 300000) {
        dispatch("getLiveDB");
      } else if (state.last_fetch === null) {
        // Looks like it might be the first time using this database for speed lets get it locally first
        dispatch("getLocalDB").then(() => {
          dispatch("getLiveDB");
        });
      }
    },
    getLocalDB({ dispatch }) {
      let response;

      axios.get(`data/${this.state.current_lang}.json`, { baseURL: window.location.origin }).then((res) => {
        response = { data: res.data };

        dispatch("loadDB", response);
      });
    },
    getLiveDB({ commit, dispatch }) {
      let requested_lag = this.state.current_lang;
      if (this.state.current_lang !== "") {
        axios
          .get("https://southwest.careleaversapp.thisisfocus.co.uk/api/app_feed/" + this.state.current_lang + ".json", {
            auth: {
              username: "appfeed",
              password: "D$TQ9Hb7AvTrwkhx",
            },
          })
          .then((response) => {
            if (requested_lag == this.state.current_lang) {
              dispatch("loadDB", response);
              commit("setLastFetch", Date.now());
              commit("setNetworkAlert", false);
            }
          })
          .catch(() => {
            dispatch("getLocalDB");
            commit("setNetworkAlert", true);
          });
      }
    },
    // Loading the databse
    loadDB({ commit }, response) {
      commit("setLastUpdate", response.data.last_update);
      commit("setConfig", response.data.config);
      commit("setAppBlocks", response.data.app_blocks.data);
      commit("setCmsPages", response.data.cms_pages.data);
      commit("setOrganisations", response.data.organisations.data);
      commit("setCategories", response.data.categories.data);
      commit("setLinks", response.data.tiles.data);
      commit("setDocuments", response.data.documents.data);
      commit("setSurveys", response.data.simple_surveys.data);
      commit("setEvents", response.data.events.data);
      commit("setOffers", response.data.offers.data);
      commit("setNews", response.data.blog_articles.data);

      if (response.data.opportunities) {
        commit("setOpportunities", response.data.opportunities.data);
      }
    },
    saveUserLocation({ commit }, userLocation) {
      localStorage.setItem("user_location", userLocation);
      commit("setUserLocation", userLocation);
    },
    saveUserType({ commit }, userType) {
      localStorage.setItem("user_type", userType);
      commit("setUserType", userType);
    },
  },
});
